import React from "react"
import { Avatar, Box, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { theme } from "../styles/theme"

type Props = {
  icon: string
  isRight: boolean
  text: string
}

const useStyles = makeStyles({
  speechBubble: {
    "& p": {
      color: `${theme.palette.text.primary}`,
      fontSize: "16px",
      lineHeight: "1.5rem",
    },
    "& a": {
      color: `${theme.palette.primary.main}`,
      fontSize: "16px",
      lineHeight: "1.5rem",
    },
  },
})

const SpeechBubble: React.FC<Props> = props => {
  const classes = useStyles()

  if (props.isRight) {
    return (
      <Box mt={3}>
        <Grid alignItems="center" container spacing={2}>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar
              alt=""
              src={props.icon}
              style={{ height: "70px", width: "70px" }}
            />
          </Grid>

          <Grid item lg={10} md={10} sm={10} xs={9}>
            <Box
              bgcolor={theme.palette.primary.light}
              borderRadius="8px"
              p={2}
              position="relative"
            >
              <Box
                border="16px solid transparent"
                borderRight={`16px solid ${theme.palette.primary.light}`}
                left="-32px"
                position="absolute"
                top="50%"
                mt={-2}
              />
              <Box
                className={classes.speechBubble}
                dangerouslySetInnerHTML={{ __html: props.text }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  } else {
    return (
      <Box mt={3}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item lg={10} md={10} sm={10} xs={9}>
            <Box
              bgcolor={theme.palette.primary.light}
              borderRadius="8px"
              p={2}
              position="relative"
            >
              <Box
                border="16px solid transparent"
                borderLeft={`16px solid ${theme.palette.primary.light}`}
                left="100%"
                position="absolute"
                top="50%"
                mt={-2}
              />

              <Box
                className={classes.speechBubble}
                dangerouslySetInnerHTML={{ __html: props.text }}
              />
            </Box>
          </Grid>

          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar
              alt=""
              src={props.icon}
              style={{ height: "70px", width: "70px" }}
            />
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default SpeechBubble
