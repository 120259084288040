import React from "react"
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { theme } from "../styles/theme"

import { MicrocmsPostsContentProduct } from "../../types/graphql-types"

type Props = {
  product: MicrocmsPostsContentProduct
  short: boolean
}

type Props2 = {
  product: MicrocmsPostsContentProduct
}

const ProductBox: React.FC<Props> = props => {
  const productName = props.product?.name || ""
  const eyecatch = props.product?.eyecatch?.url || ""
  const url = props.product?.url || ""

  if (props.short) {
    return (
      <Box mt={5}>
        <Hidden smDown>
          <Grid container spacing={2}>
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <img src={eyecatch} width="100%" />
            </Grid>

            <Grid item lg={8} md={8} sm={6} xs={6}>
              <Typography component="h3" variant="h6">
                {props.product?.name}
              </Typography>

              <Button
                color="primary"
                endIcon={<ArrowForwardIcon />}
                fullWidth
                href={url}
                size="large"
                variant="contained"
                style={{ marginTop: "24px", padding: "16px" }}
              >
                <Typography component="p" variant="h5">
                  公式サイトを見る
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Card>
            <CardActionArea>
              <CardMedia image={eyecatch} style={{ height: "200px" }} />
              <CardContent>
                <Typography component="h3" variant="h6">
                  {props.product?.name}
                </Typography>

                <Button
                  color="primary"
                  endIcon={<ArrowForwardIcon />}
                  fullWidth
                  href={url}
                  size="large"
                  variant="contained"
                  style={{ marginTop: "24px", padding: "16px" }}
                >
                  <Typography component="p" variant="h5">
                    公式サイトを見る
                  </Typography>
                </Button>
              </CardContent>
            </CardActionArea>
          </Card>
        </Hidden>
      </Box>
    )
  } else {
    return (
      <Box border={`solid 4px ${theme.palette.primary.light}`} mt={5}>
        <Box bgcolor={theme.palette.primary.light} p={2}>
          <Typography component="h3" variant="h5">
            {productName}
          </Typography>
        </Box>

        <Hidden smDown>
          <Box p={3}>
            <ProductBoxContent product={props.product} />
          </Box>
        </Hidden>

        <Hidden mdUp>
          <Box p={2}>
            <ProductBoxContent product={props.product} />
          </Box>
        </Hidden>
      </Box>
    )
  }
}

const ProductBoxContent: React.FC<Props2> = props => {
  const course = props.product?.course?.split(",") || []
  const eyecatchUrl = props.product?.eyecatch?.url || ""
  const features = props.product?.feature?.split(",") || []
  const productName = props.product?.name || ""
  const tags = props.product?.tag?.split(",") || []
  const url = props.product?.url || ""

  return (
    <>
      <img src={eyecatchUrl} width="100%" />

      <Grid container component="ul" spacing={2}>
        {tags.map((tag: string) => {
          return (
            <Grid key={tag} item component="li" style={{ listStyle: "none" }}>
              #{tag}
            </Grid>
          )
        })}
      </Grid>

      <Box mt={3}>
        <Box bgcolor={theme.palette.primary.light} p={1}>
          <Typography component="h4" variant="h6">
            「{productName}」のコース
          </Typography>
        </Box>

        <Box component="ul" mt={3} pl={4}>
          {course.map((course: string) => {
            return (
              <Typography
                key={course}
                component="li"
                gutterBottom
                variant="body1"
              >
                {course}
              </Typography>
            )
          })}
        </Box>
      </Box>

      <Box mt={3}>
        <Box bgcolor={theme.palette.primary.light} p={1}>
          <Typography component="h4" variant="h6">
            「{productName}」の特徴まとめ
          </Typography>
        </Box>

        <Box component="ul" mt={3} pl={4}>
          {features.map((feature: string) => {
            return (
              <Typography
                key={feature}
                component="li"
                gutterBottom
                variant="body1"
              >
                {feature}
              </Typography>
            )
          })}
        </Box>
      </Box>

      <Button
        color="primary"
        endIcon={<ArrowForwardIcon />}
        fullWidth
        href={url}
        size="large"
        variant="contained"
        style={{ marginTop: "24px", padding: "16px" }}
      >
        <Typography component="p" variant="h5">
          公式サイトを見る
        </Typography>
      </Button>
    </>
  )
}

export default ProductBox
