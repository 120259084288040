import React from "react"
import {
  Box,
  Button,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { makeStyles } from "@material-ui/core/styles"
import { theme } from "../styles/theme"

import { MicrocmsPostsContentCertification } from "../../types/graphql-types"

type Props = {
  certification: MicrocmsPostsContentCertification | null | undefined
}

const useStyles = makeStyles({
  certificationBox: {
    "& p": {
      color: `${theme.palette.text.primary}`,
      fontSize: "16px",
      lineHeight: "1.5rem",
    },
    "& a": {
      color: `${theme.palette.primary.main}`,
      fontSize: "16px",
      lineHeight: "1.5rem",
    },
  },
})

const CertificationBox: React.FC<Props> = props => {
  const name = props.certification?.name

  return (
    <Box border={`solid 4px ${theme.palette.primary.light}`} mt={5}>
      <Box bgcolor={theme.palette.primary.light} p={2}>
        <Typography component="h3" variant="h5">
          {name}
        </Typography>
      </Box>

      <Hidden smDown>
        <Box p={3}>
          <CertificationBoxContent certification={props.certification} />
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Box p={2}>
          <CertificationBoxContent certification={props.certification} />
        </Box>
      </Hidden>
    </Box>
  )
}

const CertificationBoxContent: React.FC<Props> = props => {
  const classes = useStyles()

  const description = props.certification?.description || ""
  const grades = props.certification?.grade?.split(",") || []
  const url = props.certification?.url || ""

  return (
    <>
      <Box
        className={classes.certificationBox}
        dangerouslySetInnerHTML={{ __html: description }}
      />

      <Box mt={3}>
        <Box bgcolor={theme.palette.primary.light} p={1}>
          <Typography component="h4" variant="h6">
            検定の種類
          </Typography>
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>級</TableCell>
              <TableCell>内容</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {grades.map((grade, index: number) => {
              const splitGrade = grade.split(":") || []
              return (
                <TableRow key={index}>
                  {splitGrade.map((content, index: number) => {
                    return <TableCell key={index}>{content}</TableCell>
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Box>

      <Button
        color="primary"
        endIcon={<ArrowForwardIcon />}
        fullWidth
        href={url}
        size="large"
        variant="contained"
        style={{ marginTop: "24px", padding: "16px" }}
      >
        <Typography component="p" variant="h5">
          詳細を見る
        </Typography>
      </Button>
    </>
  )
}

export default CertificationBox
