import React from "react"
import { Link } from "gatsby"
import { Box, List, ListItem, Typography } from "@material-ui/core"
import { theme } from "../styles/theme"

import { MicrocmsPostsTableOfContents } from "../../types/graphql-types"

type Props = {
  tableOfContents: any
}

const TableOfContents: React.FC<Props> = props => {
  return (
    <Box
      bgcolor={theme.palette.primary.light}
      borderTop={`4px solid ${theme.palette.primary.main}`}
      mt={5}
      p={3}
    >
      <Typography component="h2" gutterBottom variant="h5">
        目次
      </Typography>

      <List>
        {props.tableOfContents.map((content: any) => {
          return (
            <Link key={content.heading2Id} to={`#${content.heading2Id}`}>
              <ListItem button divider style={{ padding: "8px 0 8px 16px" }}>
                <Typography color="textPrimary" component="h3" variant="body1">
                  {content.heading2Text}
                </Typography>
              </ListItem>
            </Link>
          )
        })}
      </List>
    </Box>
  )
}

export default TableOfContents
