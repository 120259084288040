import React from "react"
import { Box } from "@material-ui/core"
import SpeechBubble from "../components/SpeechBubble"

import { MicrocmsPostsLead } from "../../types/graphql-types"

type Props = {
  lead: any
}

const Lead: React.FC<Props> = props => {
  return (
    <Box mt={5}>
      {props.lead?.map((lead: any) => {
        const speechBubbleIcon = lead.speechBubbleIcon?.image.url || ""
        const speechBubbleIsRight = lead.speechBubbleIsRight || false
        const speechBubbleText = lead.speechBubbleText || ""

        switch (lead.fieldId) {
          case "speechBubble":
            return (
              <SpeechBubble
                key={lead.speechBubbleText}
                icon={speechBubbleIcon}
                isRight={speechBubbleIsRight}
                text={speechBubbleText}
              />
            )
            break
        }
      })}
    </Box>
  )
}

export default Lead
