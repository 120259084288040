import React from "react"
import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { theme } from "../styles/theme"

import { MicrocmsPostsContent } from "../../types/graphql-types"

type Props = {
  content: MicrocmsPostsContent
}

const useStyles = makeStyles({
  list: {
    "& li": {
      lineHeight: "1.5rem",
    },
  },
})

const List: React.FC<Props> = props => {
  const classes = useStyles()

  const listContent = props.content.listContent || ""

  return (
    <Box
      border={`solid 4px ${theme.palette.primary.main}`}
      my={5}
      pb={2}
      pt={6}
      px={2}
      position="relative"
    >
      <Box
        bgcolor={theme.palette.primary.main}
        left={0}
        p={1}
        position="absolute"
        top={0}
      >
        <Typography component="h4" variant="body1">
          {props.content.listTitle}
        </Typography>
      </Box>
      <Box
        className={classes.list}
        dangerouslySetInnerHTML={{ __html: listContent }}
        pl={4}
      />
    </Box>
  )
}

export default List
