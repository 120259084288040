import React from "react"
import { graphql } from "gatsby"
import {
  Box,
  Chip,
  Divider,
  Grid,
  Hidden,
  Paper,
  Typography,
} from "@material-ui/core"
import LoopIcon from "@material-ui/icons/Loop"
import { makeStyles } from "@material-ui/core/styles"
import { theme } from "../styles/theme"

import { PostQuery } from "../../types/graphql-types"
import { MicrocmsPostsContent } from "../../types/graphql-types"

import CertificationBox from "../components/CertificationBox"
import Layout from "../components/Layout"
import Lead from "../components/Lead"
import List from "../components/List"
import ProductBox from "../components/ProductBox"
import SEO from "../components/SEO"
import SpeechBubble from "../components/SpeechBubble"
import TableOfContents from "../components/TableOfContents"
import Term from "../components/Term"

type Props = {
  data: PostQuery
}

const PostTemplate: React.FC<Props> = props => {
  const categoryName = props.data.microcmsPosts?.category?.name || ""
  const categorySlug = props.data.microcmsPosts?.category?.slug || ""
  const contents = props.data.microcmsPosts?.content
  const lead = props.data.microcmsPosts?.lead
  const tableOfContents = props.data.microcmsPosts?.tableOfContents
  const title = props.data.microcmsPosts?.title || ""
  const updatedAt = props.data.microcmsPosts?.updatedAt

  const breadcrumbs = [
    { name: "ホーム", url: "/" },
    { name: categoryName, url: `/category/${categorySlug}` },
    { name: title },
  ]

  return (
    <>
      <SEO title={title} />
      <Layout breadcrumbs={breadcrumbs}>
        <Paper>
          <Hidden smDown>
            <Box p={5}>
              <Typography component="h1" variant="h4">
                {title}
              </Typography>

              <Box my={5}>
                <Grid container alignItems="center" justify="space-between">
                  <Grid item>
                    <Chip color="primary" label={categoryName} />
                  </Grid>

                  <Grid item style={{ display: "flex", alignItems: "center" }}>
                    <LoopIcon />
                    <Typography
                      component="time"
                      variant="body1"
                      style={{ marginLeft: "8px" }}
                    >
                      {updatedAt}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Divider />

              {lead && <Lead lead={lead} />}

              {tableOfContents && (
                <TableOfContents tableOfContents={tableOfContents} />
              )}

              {contents && <Content contents={contents} />}
            </Box>
          </Hidden>

          <Hidden mdUp>
            <Box px={2} py={3}>
              <Typography component="h1" variant="h5">
                {title}
              </Typography>

              <Box my={3}>
                <Grid container alignItems="center" justify="space-between">
                  <Grid item>
                    <Chip color="primary" label={categoryName} />
                  </Grid>

                  <Grid item style={{ display: "flex", alignItems: "center" }}>
                    <LoopIcon />
                    <Typography
                      component="time"
                      variant="body1"
                      style={{ marginLeft: "8px" }}
                    >
                      {updatedAt}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Divider />

              {lead && <Lead lead={lead} />}

              {tableOfContents && (
                <TableOfContents tableOfContents={tableOfContents} />
              )}

              {contents && <Content contents={contents} />}
            </Box>
          </Hidden>
        </Paper>
      </Layout>
    </>
  )
}

type Props2 = {
  contents: any
}

const useStyles = makeStyles({
  paragraph: {
    "& p": {
      color: `${theme.palette.text.primary}`,
      fontSize: "16px",
      lineHeight: "1.5rem",
    },
    "& a": {
      color: `${theme.palette.primary.main}`,
      fontSize: "16px",
      lineHeight: "1.5rem",
    },
  },
})

const Content: React.FC<Props2> = props => {
  const classes = useStyles()

  return (
    <Box mt={5}>
      {props.contents.map((content: any, index: number) => {
        switch (content.fieldId) {
          case "heading2":
            return (
              <Box
                key={content.heading2Id}
                bgcolor={theme.palette.primary.main}
                p={2}
                my={5}
              >
                <Typography component="h2" id={content.heading2Id} variant="h5">
                  {content.heading2Text}
                </Typography>
              </Box>
            )
            break
          case "heading3":
            return (
              <Box
                key={content.heading3Text}
                borderLeft={`solid 4px ${theme.palette.primary.main}`}
                my={3}
                pl={2}
              >
                <Typography component="h3" variant="h6">
                  {content.heading3Text}
                </Typography>
              </Box>
            )
            break
          case "paragraph":
            return (
              <Box
                key={content.paragraph}
                className={classes.paragraph}
                dangerouslySetInnerHTML={{ __html: content.paragraph }}
              />
            )
            break
          case "speechBubble":
            return (
              <SpeechBubble
                key={content.speechBubbleText}
                icon={content.speechBubbleIcon.image.url}
                isRight={content.speechBubbleIsRight}
                text={content.speechBubbleText}
              />
            )
            break
          case "product":
            return (
              <ProductBox
                key={index}
                product={content.product}
                short={content.short}
              />
            )
            break
          case "certification":
            return (
              <CertificationBox
                key={content.certification.url}
                certification={content.certification}
              />
            )
            break
          case "term":
            return <Term key={content.term.name} term={content.term} />
            break
          case "list":
            return <List key={index} content={content} />
            break
        }
      })}
    </Box>
  )
}

export const query = graphql`
  query Post($id: String!) {
    microcmsPosts(id: { eq: $id }) {
      id
      postsId
      title
      publishedAt(formatString: "YYYY/MM/DD")
      updatedAt(formatString: "YYYY/MM/DD")
      description
      eyecatch {
        url
      }
      category {
        name
        slug
      }
      lead {
        fieldId
        speechBubbleIcon {
          image {
            url
          }
        }
        speechBubbleIsRight
        speechBubbleText
      }
      tableOfContents {
        heading2Id
        heading2Text
      }
      content {
        fieldId
        certification {
          description
          grade
          name
          url
        }
        heading2Id
        heading2Text
        heading3Text
        listContent
        listTitle
        paragraph
        term {
          description
          name
        }
        product {
          course
          eyecatch {
            url
          }
          feature
          name
          tag
          url
        }
        short
      }
    }
  }
`

export default PostTemplate
