import React from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { makeStyles } from "@material-ui/core/styles"
import { theme } from "../styles/theme"

import { MicrocmsPostsContentTerm } from "../../types/graphql-types"

type Props = {
  term: MicrocmsPostsContentTerm
}

const useStyles = makeStyles({
  term: {
    "& p": {
      color: `${theme.palette.text.primary}`,
      fontSize: "16px",
      lineHeight: "1.5rem",
    },
    "& a": {
      color: `${theme.palette.primary.main}`,
      fontSize: "16px",
      lineHeight: "1.5rem",
    },
  },
})

const Term: React.FC<Props> = props => {
  const classes = useStyles()

  const description = props.term.description || ""

  return (
    <Box my={5}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{
            background: `${theme.palette.primary.light}`,
          }}
        >
          <Typography component="h3" variant="body1">
            {props.term.name}とは？
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box
            className={classes.term}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default Term
